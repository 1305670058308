import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/Layout'
import * as globalStyle from '../styles/global-styles.module.css'
import RichText from '../components/RichText/RichText'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

type Props = {
  data: any
}

class Page extends Component<Props> {
  render() {
    const { data } = this.props

    const content = data.contentfulPage.content
    const dataImage = data.contentfulPage.image
    const image = getImage(dataImage)
    const alt = dataImage.title
    const order = data.contentfulPage.order

    return (
      <Layout>
        <main className={globalStyle.pageMain}>
          {typeof window !== 'undefined' && image && (
            <GatsbyImage
              objectFit="cover"
              objectPosition="center"
              image={image}
              alt={alt}
              className={globalStyle.pageImage}
              fetchpriority="high"
            />
          )}
          <div className={globalStyle.page}>
            <h1 className={globalStyle.pageTitle}>
              {data.contentfulPage.title}
            </h1>
            {/* <p style={{ fontWeight: 700, fontSize: '20px' }}>Bum bummleum</p> */}
            <RichText {...content} />
          </div>
        </main>
      </Layout>
    )
  }
}
export default Page

export const query = graphql`
  query($id: String) {
    contentfulPage(id: { eq: $id }) {
      title
      content {
        raw
      }
      image {
        gatsbyImageData(layout: FULL_WIDTH)
        title
      }
      slug
      order
    }
  }
`
